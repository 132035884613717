import { formatCurrency } from '@hapstack/common'
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Spacer,
} from '@hapstack/ui'
import { useState } from 'react'

import {
  Empty,
  EmptyDescription,
  EmptyHeader,
  EmptyIcon,
  EmptyTitle,
} from '~/components/ui/EmptyState'

import type { DepartmentSpend as DepartmentSpendType } from '../types'

const DEFAFULT_DEPTS_TO_SHOW = 8

export const DepartmentSpend = ({ spend }: { spend: DepartmentSpendType }) => {
  const [showAll, setShowAll] = useState(false)

  const visibleDepartments = showAll
    ? spend
    : spend.slice(0, DEFAFULT_DEPTS_TO_SHOW)

  return (
    <Card>
      <CardHeader>
        <div>
          <CardTitle>Spend by department</CardTitle>
          <CardDescription>Annualized run-rate</CardDescription>
        </div>
      </CardHeader>

      <CardContent>
        {visibleDepartments.length > 0 ? (
          <div className="space-y-2 text-sm font-medium">
            {visibleDepartments.map((department) => (
              <div
                key={department.name}
                className="flex items-center justify-between gap-8"
              >
                <div className="w-32 shrink-0 truncate">{department.name}</div>
                <div className="h-6 w-full">
                  <div
                    className="h-full rounded-r-md bg-accent"
                    style={{ width: `${department.percentOfMax}%` }}
                  />
                </div>
                <div className="shrink-0 text-right">
                  {formatCurrency(department.totalAnnualSpend, {
                    decimalPoints: 0,
                  })}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Empty>
            <EmptyIcon
              name="refresh-cw"
              color="purple"
            />
            <EmptyHeader>
              <EmptyTitle>No spend data.</EmptyTitle>
              <EmptyDescription>
                Add subscription details to your app to unlock spend insights.
              </EmptyDescription>
            </EmptyHeader>
          </Empty>
        )}

        {visibleDepartments.length !== spend.length || showAll ? (
          <>
            <Spacer size="sm" />
            <Button
              onClick={() => setShowAll(!showAll)}
              size="xs"
              variant="white"
              icon={showAll ? 'arrow-up' : 'arrow-down'}
            >
              {showAll ? 'Show less' : 'Show all'}
            </Button>
          </>
        ) : null}
      </CardContent>
    </Card>
  )
}
