import { cn } from '@hapstack/common'
import { LinkButton, TextLink } from '@hapstack/remix'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@hapstack/ui'
import { formatDistanceToNow } from 'date-fns'

import {
  Empty,
  EmptyDescription,
  EmptyHeader,
  EmptyIcon,
  EmptyTitle,
} from '~/components/ui/EmptyState'
import { UserAvatar } from '~/components/ui/User'
import { VendorLogo, VendorName } from '~/components/ui/Vendor'

import type { RecentActivity as RecentActivityType } from '../types'

export const ActivityFeed = ({
  activity,
}: {
  activity: RecentActivityType
}) => {
  const hasActivity = activity.length > 0

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center gap-1.5">
          <CardTitle>Activity feed</CardTitle>
          <Tooltip>
            <TooltipTrigger>
              <div
                className={cn(
                  'size-3 animate-pulse rounded-full border-2',
                  hasActivity
                    ? 'border-emerald-200 bg-emerald-400'
                    : 'border-gray-200 bg-gray-500'
                )}
              />
            </TooltipTrigger>
            <TooltipContent>
              {hasActivity
                ? `Activity received in the past 30 days`
                : `No activity received in the past 30 days`}
            </TooltipContent>
          </Tooltip>
        </div>
        <LinkButton
          to="/activity"
          variant="white"
          size="xs"
        >
          See all
        </LinkButton>
      </CardHeader>
      <CardContent>
        {hasActivity ? (
          <ul className="text-sm">
            {activity.map((session) => (
              <li
                key={session.id}
                className="rounded-lg px-2 py-3 first:pt-0 even:bg-muted/80"
              >
                <div className="flex items-center justify-between gap-4">
                  <div className="flex items-center gap-2">
                    <UserAvatar
                      size="sm"
                      src={session.user.avatarUrl}
                      fallback={session.user.displayName}
                    />
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <p className="mb-1">
                          <TextLink
                            to={`/team/${session.user.id}`}
                            variant="underline-on-hover"
                            className="font-medium"
                          >
                            {session.user.displayName}
                          </TextLink>{' '}
                          used{' '}
                          <VendorName
                            id={session.vendor.id}
                            name={session.vendor.name}
                            logoUrl={session.vendor.logoUrl}
                            description={session.vendor.description}
                          />
                        </p>

                        <div className="-mt-1 flex items-center gap-1.5 text-xs text-muted-foreground">
                          {formatDistanceToNow(new Date(session.timestamp), {
                            addSuffix: true,
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <VendorLogo
                    size="md"
                    url={session.vendor.logoUrl}
                  />
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <Empty>
            <EmptyIcon
              name="mouse-pointer-click"
              color="teal"
            />
            <EmptyHeader>
              <EmptyTitle>No recent activity.</EmptyTitle>
              <EmptyDescription>
                Make sure that at least one tracking method is enabled.
              </EmptyDescription>
            </EmptyHeader>
          </Empty>
        )}
      </CardContent>
    </Card>
  )
}
