import { cn } from '@hapstack/common'
import { LinkButton } from '@hapstack/remix'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Progress,
} from '@hapstack/ui'

import {
  Empty,
  EmptyHeader,
  EmptyIcon,
  EmptyTitle,
} from '~/components/ui/EmptyState'
import { Vendor, VendorLogo, VendorName } from '~/components/ui/Vendor'

import type { UnderutilizedApp as UnderutilizedAppType } from '../types'

export const UnderutilizedApps = ({
  apps,
}: {
  apps: UnderutilizedAppType[]
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Underutilized apps</CardTitle>
        <LinkButton
          to="/insights/utilization"
          size="sm"
          variant="white"
          className="h-8"
        >
          See all
        </LinkButton>
      </CardHeader>
      <CardContent>
        {apps.length > 0 ? (
          <div>
            {apps.map((app) => (
              <UnderutilizedApp
                key={app.appId}
                app={app}
              />
            ))}
          </div>
        ) : (
          <Empty>
            <EmptyIcon
              name="bar-chart-4"
              color="orange"
            />
            <EmptyHeader>
              <EmptyTitle>No underutilized apps detected.</EmptyTitle>
            </EmptyHeader>
          </Empty>
        )}
      </CardContent>
    </Card>
  )
}

const UnderutilizedApp = ({ app }: { app: UnderutilizedAppType }) => {
  // Graphic looks broken if utilization is 0%
  const utilizationPct = Math.max(Math.round(app.utilization * 100), 2)

  return (
    <div className="flex items-center justify-between gap-4 py-1.5 text-sm">
      <Vendor className="w-44 shrink-0">
        <VendorLogo
          src={app.vendor.logoUrl}
          fallback={app.vendor.name}
          size="sm"
        />
        <VendorName
          id={app.vendor.id}
          name={app.vendor.name}
          className="truncate"
        />
      </Vendor>
      <div className="w-full">
        <Progress
          value={utilizationPct}
          className="h-2 "
          indicatorClassName={cn({
            'bg-rose-500': utilizationPct <= 30,
            'bg-amber-500': utilizationPct > 30 && utilizationPct <= 70,
          })}
        />
      </div>
      <div className="min-w-32 shrink-0 text-right text-muted-foreground">
        {app.maus}/{app.subscription.numUnits} seats used
      </div>
    </div>
  )
}
