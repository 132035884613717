import { cn } from '@hapstack/common'
import { LinkButton, TextLink, useForm } from '@hapstack/remix'
import { Button, Card, CardContent, CardHeader, CardTitle } from '@hapstack/ui'
import { format } from 'date-fns'
import type { ComponentProps } from 'react'

import {
  Empty,
  EmptyHeader,
  EmptyIcon,
  EmptyTitle,
} from '~/components/ui/EmptyState'
import { VendorLogo, VendorName } from '~/components/ui/Vendor'

import { INTENTS, type ShadowApp as ShadowAppType } from '../types'

export const ShadowApps = ({ apps }: { apps: ShadowAppType[] }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Shadow apps</CardTitle>
        <LinkButton
          to="/insights"
          variant="white"
          size="xs"
        >
          See all
        </LinkButton>
      </CardHeader>
      <CardContent>
        {apps.length > 0 ? (
          <ul className="divide-y divide-border">
            {apps.map((app) => (
              <ShadowApp
                key={app.vendor.id}
                app={app}
              />
            ))}
          </ul>
        ) : (
          <Empty>
            <EmptyIcon
              name="ghost"
              color="red"
            />
            <EmptyHeader>
              <EmptyTitle>No shadow apps detected.</EmptyTitle>
            </EmptyHeader>
          </Empty>
        )}
      </CardContent>
    </Card>
  )
}

const ShadowApp = ({
  app,
  className,
}: {
  app: ShadowAppType
} & ComponentProps<'div'>) => {
  const form = useForm({
    intent: INTENTS.Enum.ignoreVendor,
    defaultValues: { vendorId: app.vendor.id },
    submitConfig: { action: '/?index' },
    useFetcher: true,
    fetcherKey: `${INTENTS.Enum.ignoreVendor}-${app.vendor.id}`,
  })

  return (
    <li className={cn('flex items-center justify-between py-2.5', className)}>
      <div className="flex items-center gap-2">
        <VendorLogo
          url={app.vendor.logoUrl}
          size="sm"
        />
        <div className="flex flex-col gap-0.5">
          <VendorName
            id={app.vendor.id}
            name={app.vendor.name}
            logoUrl={app.vendor.logoUrl}
            description={app.vendor.description}
            className="text-sm"
          />
          <div className="-mt-1 text-xs text-muted-foreground">
            Last used by{' '}
            <TextLink
              to={`/team/${app.user.id}`}
              variant="underline-on-hover"
            >
              {app.user.displayName}
            </TextLink>{' '}
            on {format(new Date(app.vendor.lastAccessed!), 'MMM d')}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Button
          variant="white"
          size="xs"
          className="min-w-0 font-normal"
          loading={form.formState.isSubmitting}
          onClick={form.handleSubmit}
        >
          Ignore
        </Button>
      </div>
    </li>
  )
}
