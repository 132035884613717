import { UTCDate } from '@date-fns/utc'
import { cn } from '@hapstack/common'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
  Tag,
} from '@hapstack/ui'
import {
  addMonths,
  differenceInMonths,
  isPast,
  isSameMonth,
  isToday,
  startOfMonth,
  subMonths,
} from 'date-fns'
import { useState } from 'react'

import { Vendor, VendorLogo, VendorName } from '~/components/ui/Vendor'
import { formatUTC } from '~/utils/dates'

import type { UpcomingDates } from '../types'

export const FUTURE_MONTH_LIMIT = 12

const daysOfWeek = ['S', 'M', 'T', 'W', 'Th', 'F', 'S']

export const CalendarView = ({ dates }: { dates: UpcomingDates }) => {
  const startOfThisMonth = startOfMonth(new UTCDate())
  const [selectedMonth, setSelectedMonth] = useState(startOfThisMonth)

  const canNavigateForward =
    differenceInMonths(selectedMonth, startOfThisMonth) < FUTURE_MONTH_LIMIT

  const canNavigateBackward =
    differenceInMonths(selectedMonth, startOfThisMonth) > 0

  const daysThisMonth = dates.filter((d) =>
    isSameMonth(new UTCDate(d.date), selectedMonth)
  )

  const startEmptyDays = daysThisMonth.at(0)?.dayOfWeek ?? 0

  const endEmptyDays =
    6 - (daysThisMonth.at(daysThisMonth.length - 1)?.dayOfWeek ?? 0)

  return (
    <Card>
      <CardHeader>
        <CardTitle>Subscription calendar</CardTitle>
        <div className="flex items-center gap-2">
          <Tag
            color="grey"
            rounded="full"
            size="lg"
          >
            {formatUTC(selectedMonth, 'MMMM yyyy')}
          </Tag>
          <IconButton
            icon="arrow-left"
            onClick={() => setSelectedMonth(subMonths(selectedMonth, 1))}
            label="previous month"
            disabled={!canNavigateBackward}
          />

          <IconButton
            icon="arrow-right"
            onClick={() => setSelectedMonth(addMonths(selectedMonth, 1))}
            label="next month"
            disabled={!canNavigateForward}
          />
        </div>
      </CardHeader>
      <CardContent className="pt-8">
        <div className="mb-2 grid grid-cols-7 gap-1 text-sm font-medium text-muted-foreground">
          {daysOfWeek.map((day) => (
            <div
              key={day}
              className="w-full text-center"
            >
              {day}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-7 gap-1">
          {Array.from({ length: startEmptyDays }).map((_, i) => (
            <div
              key={`start-empty-${i}`}
              className="h-full w-full"
            />
          ))}

          {daysThisMonth.map((day) => (
            <CalendarDay
              key={`${day.month}-${day.dayOfMonth}-${day.year}`}
              day={day}
            />
          ))}

          {Array.from({ length: endEmptyDays }).map((_, i) => (
            <div
              key={`end-empty-${i}`}
              className="h-full w-full"
            />
          ))}
        </div>
      </CardContent>
    </Card>
  )
}

const CalendarDay = ({ day }: { day: UpcomingDates[number] }) => {
  const dayIsPast = isPast(new UTCDate(day.date))
  const dayIsToday = isToday(new UTCDate(day.date))

  return (
    <div
      className={cn(
        'flex min-h-16 flex-col gap-2 rounded-lg border border-border p-2',
        dayIsPast && !dayIsToday && 'opacity-30',
        dayIsToday && 'border-accent'
      )}
    >
      <div className="text-xs text-muted-foreground">{day.dayOfMonth}</div>
      {day.milestones.length ? (
        <div className="flex flex-wrap items-center gap-1">
          {day.milestones.map((m) => (
            <Popover key={m.subscriptionId}>
              <PopoverTrigger>
                <VendorLogo
                  url={m.vendor.logoUrl}
                  fallback={m.vendor.name}
                  size="sm"
                  className="transition-all duration-300 hover:scale-110 hover:bg-muted"
                />
              </PopoverTrigger>
              <PopoverContent
                align="start"
                className="space-y-2"
              >
                <Vendor>
                  <VendorLogo
                    src={m.vendor.logoUrl}
                    fallback={m.vendor.name}
                    size="sm"
                  />
                  <VendorName
                    id={m.vendor.id}
                    name={m.vendor.name}
                  />
                </Vendor>
                <Separator />
                <div className="text-sm">
                  {m.subscriptionType === 'recurring'
                    ? 'Renews'
                    : m.milestoneType === 'termEnd'
                      ? 'Contract ends'
                      : 'Notice deadline'}{' '}
                  on {formatUTC(m.milestoneDate, 'MMM d')}
                </div>
              </PopoverContent>
            </Popover>
          ))}
        </div>
      ) : null}
    </div>
  )
}
