import { formatCurrency } from '@hapstack/common'
import { badRequest, parseFormData, SuspenseWrapper } from '@hapstack/remix'
import { Spacer } from '@hapstack/ui'
import type { ActionFunctionArgs, LoaderFunctionArgs } from '@remix-run/node'
import { json, useLoaderData } from '@remix-run/react'
import { collect, inputFromUrl } from 'composable-functions'

import { PageHeader } from '~/components/ui/DashboardHeadings'
import {
  Metric,
  MetricDescription,
  MetricLabel,
  MetricValue,
} from '~/components/ui/Metric'
import { setCookie } from '~/utils/cookie.server'
import { env } from '~/utils/env.server'
import { mutate } from '~/utils/mutate.server'
import { query } from '~/utils/query.server'

import { ActivityFeed } from './components/ActivityFeed'
import { CalendarView } from './components/CalendarView'
import { DepartmentSpend } from './components/DepartmentSpend'
import { ShadowApps } from './components/ShadowApps'
import { UnderutilizedApps } from './components/UnderutilizedApps'
import { ignoreVendor } from './mutations/ignoreVendor.server'
import { getActiveSubscriptionCount } from './queries/getActiveSubscriptionCount.server'
import { getActiveUserCount } from './queries/getActiveUserCount.server'
import { getSpendSnapshot } from './queries/getAnnualizedSpend.server'
import { getRecentActivity } from './queries/getRecentActivity.server'
import { getShadowApps } from './queries/getShadowApps.server'
import { getSpendByDepartment } from './queries/getSpendByDepartment.server'
import { getUnderutilizedApps } from './queries/getUnderutilizedApps'
import { getUpcomingDates } from './queries/getUpcomingDates.server'
import { INTENTS } from './types'

export const loader = async ({ request }: LoaderFunctionArgs) => {
  return query({
    request,
    query: collect({
      activeSubscriptionCount: getActiveSubscriptionCount,
      activeUsers: getActiveUserCount,
      recentActivity: getRecentActivity,
      shadowApps: getShadowApps,
      spendByDepartment: getSpendByDepartment,
      spendSnapshot: getSpendSnapshot,
      upcomingDates: getUpcomingDates,
      underutilizedApps: getUnderutilizedApps,
    }),
    environment: inputFromUrl(request),
    defer: true,
  })
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await parseFormData(request)
  const intent = formData.intent

  if (intent === INTENTS.Enum.ignoreVendor) {
    return await mutate({
      request,
      input: formData,
      mutation: ignoreVendor,
    })
  }

  if (formData.intent === INTENTS.Enum.ackReleaseDialog) {
    const headers = await setCookie({
      cookieName: 'lastReleaseAck',
      value: env.APP_VERSION,
    })

    return json({ success: true }, { headers })
  }

  throw badRequest()
}

export default function DashboardRoute() {
  const { result } = useLoaderData<typeof loader>()

  return (
    <div>
      <PageHeader
        title="Dashboard"
        icon="gauge-circle"
      />
      <Spacer size="lg" />

      <SuspenseWrapper promise={result}>
        {({
          activeSubscriptionCount,
          activeUsers,
          recentActivity,
          shadowApps,
          spendByDepartment,
          spendSnapshot,
          upcomingDates,
          underutilizedApps,
        }) => {
          return (
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-4 gap-4">
                <Metric icon="credit-card">
                  <MetricLabel>Annual spend</MetricLabel>
                  <MetricValue>
                    {formatCurrency(spendSnapshot?.annualSpend || 0, {
                      decimalPoints: 0,
                    })}
                  </MetricValue>
                  <MetricDescription>Current run-rate</MetricDescription>
                </Metric>
                <Metric icon="credit-card">
                  <MetricLabel>Monthly spend</MetricLabel>
                  <MetricValue>
                    {formatCurrency(spendSnapshot?.monthlySpend || 0, {
                      decimalPoints: 0,
                    })}
                  </MetricValue>
                  <MetricDescription>Current run-rate</MetricDescription>
                </Metric>

                <Metric icon="mouse-pointer-click">
                  <MetricLabel>Active users</MetricLabel>
                  <MetricValue>{activeUsers?.currentPeriodCount}</MetricValue>
                  <MetricDescription>
                    {generatePeriodChangeLanguage(
                      activeUsers.currentPeriodCount,
                      activeUsers.previousPeriodCount
                    )}
                  </MetricDescription>
                </Metric>

                <Metric icon="refresh-cw">
                  <MetricLabel>Active subscriptions</MetricLabel>
                  <MetricValue>{activeSubscriptionCount}</MetricValue>
                </Metric>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-2 space-y-4">
                  <DepartmentSpend spend={spendByDepartment} />
                  <CalendarView dates={upcomingDates} />
                  <UnderutilizedApps apps={underutilizedApps} />
                </div>
                <div className="space-y-4">
                  <ActivityFeed activity={recentActivity} />
                  <ShadowApps apps={shadowApps} />
                </div>
              </div>
            </div>
          )
        }}
      </SuspenseWrapper>
    </div>
  )
}

function generatePeriodChangeLanguage(
  currentPeriodCount: number,
  previousPeriodCount: number
) {
  const change = currentPeriodCount - previousPeriodCount
  if (change === 0) return 'No change vs prior 30 days'
  if (change > 0) return `+${change} vs prior 30 days`
  return `${change} vs prior 30 days`
}
